import React from "react"
import { Link } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import BgImg from "gatsby-background-image"
import * as styles from "./hero.module.scss"
import { RichTextElement } from "@kentico/gatsby-kontent-components"
import { useIsSsr } from "../../assets/js/helper"

const Hero = ({ banner, currentPage }) => {
  const isSsr = useIsSsr()
  const { mobile, background_image, heading, subheading, buttons } = banner
  const { button_text, linked_page, anchor_reference } =
    buttons.value[0].elements

  const style = {
    background: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${mobile.value[0].fluid.src})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover !important",
  }

  const getButtons = () => {
    if (linked_page !== undefined) {
      const { slug } = linked_page.value[0].elements
      return (
        <Link to={slug.value} className="solid-secondary-button">
          {button_text.value}
        </Link>
      )
    } else {
      const anchorRef = anchor_reference.value[0].name ? anchor_reference.value[0].name : 'form-banner'
      return (
        <AnchorLink
          to={`${currentPage}#${anchorRef}`}
          className="solid-secondary-button"
        >
          {button_text.value}
        </AnchorLink>
      )
    }
  }
  if (!isSsr && window.innerWidth < 768) {
    return (
      <div style={style} className={styles.hero}>
        <section
          className={`${styles.bannerContainer} ${styles.bannerContent}`}
        >
          <h1>{heading.value}</h1>
          <RichTextElement value={subheading.value} />
          <div className={styles.homePageBannerLinks}>{getButtons()}</div>
        </section>
      </div>
    )
  } else if (!isSsr && window.innerWidth >= 768) {
    return (
      <BgImg
        fluid={background_image.value[0].fluid}
        alt={background_image.value[0].description}
        className={styles.hero}
      >
        <section className={styles.bannerContainer}>
          <div className={styles.bannerContent}>
            <h1>{heading.value}</h1>
            <RichTextElement value={subheading.value} />
            <div className={styles.homePageBannerLinks}>{getButtons()}</div>
          </div>
        </section>
      </BgImg>
    )
  } else {
    return (
      <div className={styles.hero}>
        <div className={styles.bannerContent}>
          <h1>{heading.value}</h1>
          <RichTextElement value={subheading.value} />
          <div className={styles.homePageBannerLinks}>{getButtons()}</div>
        </div>
      </div>
    )
  }
}
Hero.defaultProps = {
  banner: {},
}
export default Hero
